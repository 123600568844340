import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import icClose from '@iconify/icons-ic/twotone-close';
import { scaleIn400ms } from '../../animations/scale-in.animation';
import { LayoutService } from '../../services/layout.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { SearchService } from '../../services/search.service';
import { TranslateService } from '@ngx-translate/core';

// Serviços
import { ReservaService } from '../../../app/services/Reservas.service';
import {fadeInRight400ms} from "../../animations/fade-in-right.animation";
import {stagger40ms} from "../../animations/stagger.animation";
import {fadeInUp400ms} from "../../animations/fade-in-up.animation";
import {scaleFadeIn400ms} from "../../animations/scale-fade-in.animation";

@UntilDestroy()
@Component({
  selector: 'vex-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    scaleIn400ms
  ]
})
export class SearchComponent implements OnInit, OnDestroy {

  show$ = this.layoutService.searchOpen$;
  searchCtrl = new FormControl();
  icClose = icClose;

  Reserve_Search = '';
  Reserve_Result = '';

  @ViewChild('searchInput', { static: true }) input: ElementRef;

  constructor(private layoutService: LayoutService,
              private searchService: SearchService,
              private ReservasService: ReservaService,
              private translate: TranslateService) { }

  ngOnInit() {
    this.searchService.isOpenSubject.next(true);
    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.searchService.valueChangesSubject.next(value));

    this.show$.pipe(
      filter(show => show),
      untilDestroyed(this)
    ).subscribe(() => this.input.nativeElement.focus());
  }

  close() {
    this.layoutService.closeSearch();
    this.searchCtrl.setValue(undefined);
    this.searchService.isOpenSubject.next(false);
    this.Reserve_Result = '';
  }

  search() {
    this.searchService.submitSubject.next(this.searchCtrl.value);
    if (this.searchCtrl.value != '') {
      this.ReservasService.Localizar_Reserva(this.searchCtrl.value).subscribe((data: any) => {

            let Result = '';

            if (data.length == 0) {
              this.Reserve_Result = this.translate.instant('GERAL.SEARCHNORESULT');
            } else {
              let row = data[0];

              if (row.ClientStatus == 'CRE') { this.Reserve_Result  = this.translate.instant('RESERVES.CRE'); }
              if (row.ClientStatus == 'REC') { this.Reserve_Result  = this.translate.instant('RESERVES.REC'); }
              if (row.ClientStatus == 'WHS') { this.Reserve_Result  = this.translate.instant('RESERVES.WHS'); }
              if (row.ClientStatus == 'DIS') { this.Reserve_Result  = this.translate.instant('RESERVES.DIS'); }
              if (row.ClientStatus == 'DEL') { this.Reserve_Result  = this.translate.instant('RESERVES.DEL'); }
              if (row.ClientStatus == 'VAL') { this.Reserve_Result  = this.translate.instant('RESERVES.VAL'); }
            }

            //this.triggerFalseClick();

          },
          error => {
            console.log(error);
          });

    }
    //this.close();
  }

  ngOnDestroy(): void {
    this.layoutService.closeSearch();
    this.searchCtrl.setValue(undefined);
    this.searchService.isOpenSubject.next(false);
    this.Reserve_Result = '';
  }
}
