// Angular
import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, ChangeDetectorRef, NgModule } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import icDateRange from '@iconify/icons-ic/date-range';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icFlag from '@iconify/icons-ic/flag';
import icTimer from '@iconify/icons-ic/timer';
import icTimerOff from '@iconify/icons-ic/timer-off';
import icVisibility from '@iconify/icons-ic/visibility';
import icVisibilityOff from '@iconify/icons-ic/visibility-off';

import { ReservaService } from '../../../../services/Reservas.service';
import { AuthService } from '../../../../../app/core/auth';
import { TranslateService } from '@ngx-translate/core';
import { NotificationDialog } from './NotificationDialog.component';

import { LoadingFreeze } from './LoadingFreeze';
import {Customer} from "../../../../../../../demo/src/app/pages/apps/aio-table/interfaces/customer.model";


@Component({

    selector: 'NOX-ChangePassword-Dialog',
    templateUrl: './ChangePassword.component.html',

})

export class ChangePasswordDialog implements OnInit {

    @Output() submitClicked = new EventEmitter<any>();

	form: FormGroup;

	inputType = 'password';
	visible = false;


	icDateRange = icDateRange;
	icMoreVert = icMoreVert;
	icClose = icClose;

	icPrint = icPrint;
	icDownload = icDownload;
	icDelete = icDelete;

	icPerson = icPerson;
	icMyLocation = icMyLocation;
	icLocationCity = icLocationCity;
	icEditLocation = icEditLocation;
	icPhone = icPhone;
	icFlag = icFlag;
	icTimer = icTimer;
	icTimerOff = icTimerOff;
	icVisibility = icVisibility;
	icVisibilityOff = icVisibilityOff;

	Contact: any = {};


    constructor(public dialogRef: MatDialogRef<ChangePasswordDialog>,
				public ReservasService: ReservaService,
				public auth: AuthService,
				public translate: TranslateService,
				public Dialog: MatDialog,
				private fb: FormBuilder,
				private cd: ChangeDetectorRef) {
    }

	ngOnInit() {
		this.form = this.fb.group({
			oldPassword: ['', Validators.required],
			password: ['', Validators.required],
			passwordConfirm: ['', Validators.required],
		});
	}



    onBackClick(): void {
        this.dialogRef.close();
    }

    onSaveClick(): void 
    {
		let errors;
		//debugger;

		// Validar dados
		const controls = this.form.controls;
		/** check form */
		if (this.form.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		//this.loading = true;

		let toSave = {
			oldPassword: controls['oldPassword'].value,
			password: controls['password'].value,
			passwordConfirm: controls['passwordConfirm'].value,
		};

		if(toSave.oldPassword === toSave.password || toSave.oldPassword === toSave.passwordConfirm) {

			let RefNotDialog = this.Dialog.open(NotificationDialog,
				{
					width: '450px',
					disableClose: true,
					data: {
						Type: "Error",
						Title: "",
						Description: this.translate.instant('DIALOGS.OLDNEWPASSWORDEQUAL'),
						Action: false,
						Action_Caption: "",
					}
				});
			return;
		}

		if(toSave.password !== toSave.passwordConfirm) {

			let RefNotDialog = this.Dialog.open(NotificationDialog,
				{
					width: '450px',
					disableClose: true,
					data: {
						Type: "Error",
						Title: "",
						Description: this.translate.instant('DIALOGS.PASSWORDCONFIRMNOTEQUAL'),
						Action: false,
						Action_Caption: "",
					}
				});
			return;
		}

		if(!this.CheckPassword(toSave.password)) {

			let RefNotDialog = this.Dialog.open(NotificationDialog,
				{
					width: '450px',
					disableClose: true,
					data: {
						Type: "Error",
						Title: "",
						Description: this.translate.instant('GERAL.PASSWORDCHECK'),
						Action: false,
						Action_Caption: "",
					}
				});
			return;
		}


			let W8Dialog = this.Dialog.open(LoadingFreeze,
				{
					panelClass: 'loading-freeze',
					disableClose: true ,
					width: '15%',
					data:{Title: '' }
				});


			this.auth.Change_Password(toSave).subscribe((result: any) => {

					W8Dialog.close();

					if (result.Success == true) {

						let RefNotDialog = this.Dialog.open(NotificationDialog,
							{
								width: '450px',
								disableClose: true,
								data: {
									Type: "Success",
									Title: "" ,
									Description: this.translate.instant('GERAL.PASSWORDCHANGED')
									//	Action: result.PrintLabels ? true : false,
									//	Action_Caption: this.translate.instant('RESERVES.PRINT_LABELS'),
								}
							});

						this.dialogRef.close(toSave);

					} else {

						W8Dialog.close();

						let RefNotDialog = this.Dialog.open(NotificationDialog,
							{
								width: '450px',
								disableClose: true,
								data: {
									Type: "Error",
									Title: "",
									Description: result.Message,
									Action: false,
									Action_Caption: "",
								}
							});

					}

				},
				error => {
					W8Dialog.close();
					console.log(error);
					let RefNotDialog = this.Dialog.open(NotificationDialog,
						{
							width: '450px',
							disableClose: true,
							data: {
								Type: "Error",
								Title: "",
								Description: error.error.error,
								Action: false,
								Action_Caption: ''
							}
						});
				}
			);


    }

	toggleVisibility() {
		if (this.visible) {
			this.inputType = 'password';
			this.visible = false;
			this.cd.markForCheck();
		} else {
			this.inputType = 'text';
			this.visible = true;
			this.cd.markForCheck();
		}
	}

	CheckPassword(inputtxt) {
		let passw = /^\w{7,14}$/;
		if(inputtxt.match(passw))
		{
			return true;
		}
		else
		{
			return false;
		}
	}

}
