<div class="dropdown" *ngIf="user$ | async as _user">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="dropdown-heading-icon" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon [icIcon]="icPerson"></mat-icon>
      </div>
      <div class="dropdown-heading">{{_user.DisplayName}}</div>
    </div>

    <button [matMenuTriggerFor]="settingsMenu"
            mat-icon-button
            matTooltip="Settings"
            matTooltipPosition="before"
            type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button>
  </div>

  <div class="dropdown-content">

    <a (click)="onOpenSimulator()"
       class="notification cursor-pointer"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon
              [icIcon]="icAssignment"
              color="text-teal"
              class="notification-icon"
              fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{'GERAL.SIMULATEEXPEDITION' | translate}}</div>
        <div class="notification-description">{{'GERAL.SIMULATEEXPEDITIONCOST' | translate}}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>


    <a (click)="onChangePassword()"
       class="notification cursor-pointer"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon
              [icIcon]="icKey"
              color="text-teal"
              class="notification-icon"
              fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{'GERAL.CHANGEPASSWORD' | translate}}</div>
        <div class="notification-description">{{'GERAL.CHANGEPASSWORDLABEL' | translate}}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>


    <a (click)="selectedStyle === Style.dark ? disableDarkMode() : enableDarkMode()"
       *ngIf="selectedStyle$ | async as selectedStyle"
       class="notification cursor-pointer"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon
              [icIcon]="icInvertColors"
              color="text-primary"
              class="notification-icon"
              fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{'GERAL.DARKTHEME' | translate}}</div>
        <div class="notification-description">{{'GERAL.DARKTHEMELABEL' | translate}}</div>
      </div>
      <!--<mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>-->
    </a>

    <a (click)="openSettings()"
       class="notification cursor-pointer hide-settings"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon
                [icIcon]="icColorLens"
                color="primary"
                class="notification-icon"
                fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{'GERAL.THEMECONFIG' | translate}}</div>
        <div class="notification-description">{{'GERAL.THEMECONFIGLABEL' | translate}}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>



    <!--<a (click)="close()"
       *ngFor="let item of items; trackBy: trackById"
       [routerLink]="item.route"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <mat-icon [icIcon]="item.icon"
                [ngClass]="item.colorClass"
                class="notification-icon"
                fxFlex="none"></mat-icon>
      <div fxFlex="auto">
        <div class="notification-label">{{ item.label }}</div>
        <div class="notification-description">{{ item.description }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>-->
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="space-between center">
    <button [matMenuTriggerFor]="statusMenu" class="dropdown-footer-select" mat-button type="button"
            style="visibility:hidden;">
      <ng-container *ngFor="let status of statuses; trackBy: trackById">
        <span *ngIf="status === activeStatus">
          <mat-icon [icIcon]="status.icon" [ngClass]="status.colorClass"></mat-icon>
          <span>{{ status.label }}</span>
          <mat-icon [icIcon]="icArrowDropDown" class="dropdown-footer-select-caret"></mat-icon>
        </span>
      </ng-container>
    </button>
    <!--<a (click)="close()" [routerLink]="['/login']" color="primary" mat-button>LOGOUT</a>-->
    <a (click)="close()"  color="primary" mat-button>LOGOUT</a>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="setStatus(status)"
          *ngFor="let status of statuses; trackBy: trackById"
          mat-menu-item>
    <ic-icon [icon]="status.icon" [ngClass]="status.colorClass" inline="true" size="24px"></ic-icon>
    <span>{{ status.label }}</span>
  </button>
</mat-menu>


<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <!--<button mat-menu-item>
    <mat-icon [icIcon]="icBusiness"></mat-icon>
    <span>Change Address</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icVerifiedUser"></mat-icon>
    <span>Change Username</span>
  </button>-->

  <!--<button mat-menu-item (click)="onChangePassword()">
    <mat-icon [icIcon]="icLock"></mat-icon>
    <span>{{'GERAL.CHANGEPASSWORD' | translate}}</span>
  </button>-->

  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>{{'GERAL.DISABLENOTIFICATONS' | translate}}</span>
  </button>
</mat-menu>
