import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import emojionePT from '@iconify/icons-emojione/flag-for-flag-portugal';
import emojioneUK from '@iconify/icons-emojione/flag-for-flag-united-kingdom';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icTruck from '@iconify/icons-ic/local-shipping';
import icFolder from '@iconify/icons-ic/folder-open';
import icMyLocation from '@iconify/icons-ic/my-location';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import icSearch from '@iconify/icons-ic/twotone-search';
import icLibraryBooks from '@iconify/icons-ic/library-books';
import {SimulatorDialog} from "../../../app/views/themes/geral/dialogs/Simulator.component";
import {MatDialog} from "@angular/material/dialog";


interface Language {
  lang: string;
  name: string;
  flag: any;
  active?: boolean;
}

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;
  showBilling = false;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneUS = emojioneUS;
  emojioneDE = emojioneDE;
  emojionePT = emojionePT;
  emojioneUK = emojioneUK;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;
  icLayer = icLayers;
  icTruck = icTruck;
  icFolder = icFolder;
  icMyLocation = icMyLocation;
  icLibraryBooks = icLibraryBooks;

  currentLanguage: Language =  {
    lang: 'pt',
    name: 'Portuguese',
    flag: emojionePT,
    active: false
  };

  languages: Language[] = [
    {
      lang: 'pt',
      name: 'Portuguese',
      flag: emojionePT,
      active: false
    },
    {
      lang: 'en',
      name: 'English',
      flag: emojioneUK,
      active: false
    }
  ];

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private navigationService: NavigationService,
              private popoverService: PopoverService,
              private translate: TranslateService,
              public Dialog: MatDialog) {
  }

  ngOnInit() {
    let lang = this.translate.currentLang;
    this.languages.forEach((language) => {
      if (language.lang === lang) {
        language.active = true;
        this.currentLanguage = language;
      } else {
        language.active = false;
      }
    });
    let profile = localStorage.getItem('Profile');
    if(profile == 'FAT') {
      this.showBilling = true;
    }
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement) {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      position: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  onOpenSimulator() {

    //this.popoverRef.close();

    let dialogRef = this.Dialog.open(SimulatorDialog, {
      disableClose: true ,
      closeOnNavigation: true,
      width: '500px',
      panelClass: 'custom-dialog',
      data: { Tile: "Change Password"  }
    });

    /*dialogRef.afterClosed().subscribe(result => {

      if(result){

      }

    });*/

    const dialogSubmitSubscription =
        dialogRef.componentInstance.submitClicked.subscribe(result => {

          dialogSubmitSubscription.unsubscribe();
        });
  }


  /**
   * Set language
   *
   * @param lang: any
   */
  setLanguage(lang: string) {
    this.languages.forEach((language) => {
      if (language.lang === lang) {
        language.active = true;
        this.currentLanguage = language;
      } else {
        language.active = false;
      }
    });

    localStorage.setItem('lang',lang);
    this.translate.use(lang);
  }
}
