import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import {ReservaService} from "../../../../app/services/Reservas.service";

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  notifications = "0";
  hidden = true;
  dropdownOpen: boolean;
  icNotificationsActive = icNotificationsActive;

  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,
              private ReservasService: ReservaService) {
  }

  ngOnInit() {
    this.updateNotifications();
  }

  showPopover() {
    this.dropdownOpen = true;
    
    const popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    this.updateNotifications();
    this.cd.markForCheck();

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.updateNotifications();
      this.cd.markForCheck();
    });

    popoverRef.afterClearNotification$.subscribe(() => {
      this.updateNotifications();
      this.cd.markForCheck();
    });

  }

  updateNotifications() {
    this.ReservasService.Get_Notifications().subscribe((data: any) => {
      if(data) {
        this.notifications = data.length;
        if(data.length && data.length > 0) {
          this.hidden = false;
        }else {
          this.hidden = true;
        }
        this.cd.markForCheck();
      }
    }, error => {
      //console.log(error);
    });
  }
}
