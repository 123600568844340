<div (keyup.escape)="close()" [class.show]="show$ | async" class="search">
  <button (click)="close()"
          class="ltr:right-12 rtl:left-12 top-12 absolute"
          color="primary"
          mat-icon-button
          type="button">
    <mat-icon [icIcon]="icClose"></mat-icon>
  </button>

  <input #searchInput (keyup.enter)="search()" [formControl]="searchCtrl" class="search-input" placeholder="{{'GERAL.SEARCH' | translate}}..."/>
  <div class="search-hint">{{'GERAL.SEARCHPRESSENTER' | translate}}</div>
  <div><h2 class="headline text-primary" style="font-weight: 600;font-size: xx-large;">{{Reserve_Result}}</h2></div>
</div>

<div (click)="close()" *ngIf="show$ | async" class="search-overlay"></div>

