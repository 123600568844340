import { Component, OnInit } from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icClearAll from '@iconify/icons-ic/twotone-clear-all';
import icShoppingBasket from '@iconify/icons-ic/twotone-shopping-basket';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icInsertChart from '@iconify/icons-ic/twotone-insert-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icDescription from '@iconify/icons-ic/twotone-description';
import icFeedback from '@iconify/icons-ic/twotone-feedback';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icFileCopy from '@iconify/icons-ic/twotone-file-copy';
import icNotification from '@iconify/icons-ic/outline-notification-important';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icFlag from '@iconify/icons-ic/flag';
import icHome from '@iconify/icons-ic/home';
import icNavigation from '@iconify/icons-ic/navigation';
import icLocalShipping from '@iconify/icons-ic/local-shipping';
import icLocationOn from '@iconify/icons-ic/location-on';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { ReservaService } from '../../../../../app/services/Reservas.service';
import { LoadingFreeze } from "../../../../../app/views/themes/geral/dialogs/LoadingFreeze";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PopoverRef} from "../../../../components/popover/popover-ref";


@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  notifications: any; /*= [
    {
      id: '1',
      label: 'New Order Received',
      icon: icShoppingBasket,
      colorClass: 'text-primary',
      datetime: DateTime.local().minus({ hour: 1 })
    },
    {
      id: '2',
      label: 'New customer has registered',
      icon: icAccountCircle,
      colorClass: 'text-orange',
      datetime: DateTime.local().minus({ hour: 2 })
    },
    {
      id: '3',
      label: 'Campaign statistics are available',
      icon: icInsertChart,
      colorClass: 'text-purple',
      datetime: DateTime.local().minus({ hour: 5 })
    },
    {
      id: '4',
      label: 'Project has been approved',
      icon: icCheckCircle,
      colorClass: 'text-green',
      datetime: DateTime.local().minus({ hour: 9 })
    },
    {
      id: '5',
      label: 'Client reports are available',
      icon: icDescription,
      colorClass: 'text-primary',
      datetime: DateTime.local().minus({ hour: 30 })
    },
    {
      id: '6',
      label: 'New review received',
      icon: icFeedback,
      colorClass: 'text-orange',
      datetime: DateTime.local().minus({ hour: 40 }),
      read: true
    },
    {
      id: '7',
      label: '22 verified registrations',
      icon: icVerifiedUser,
      colorClass: 'text-green',
      datetime: DateTime.local().minus({ hour: 60 })
    },
    {
      id: '8',
      label: 'New files available',
      icon: icFileCopy,
      colorClass: 'text-amber',
      datetime: DateTime.local().minus({ hour: 90 })
    }
  ];*/

  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  icNotification = icNotification;
  icPerson = icPerson;
  icMyLocation = icMyLocation;
  icLocationCity = icLocationCity;
  icEditLocation = icEditLocation;
  icFlag = icFlag;
  icHome = icHome;
  icNavigation = icNavigation;
  icLocalShipping = icLocalShipping
  icAccountCircle = icAccountCircle;
  icLocationOn = icLocationOn;

  trackById = trackById;

  constructor(private translate: TranslateService,
              private ReservasService: ReservaService,
              private popoverRef: PopoverRef<ToolbarNotificationsDropdownComponent>,
              private snackbar: MatSnackBar,
              public Dialog: MatDialog) { }

  ngOnInit() {
    this.updateNotifications();
  }

  MarkRead(id) {

    let ids = [];
    let transl = this.translate.instant('GERAL.NOTIFICATIONSREAD');
    if(id == 'all' && this.notifications) {
      for(let i=0; i< this.notifications.length; i++) {
        ids.push(this.notifications[i]['id']);
      }
    } else if(id != 'all') {
      ids.push(id);
      transl = this.translate.instant('GERAL.NOTIFICATIONREAD');
    }

    if(ids.length == 0) {
      this.snackbar.open(transl, this.translate.instant('GERAL.CLOSE'), {
        duration: 2000,
        verticalPosition: 'bottom'
      });
      //this.popoverRef.clearNotification();
      return
    }

    let W8Dialog = this.Dialog.open(LoadingFreeze,
        {
          panelClass: 'loading-freeze',
          disableClose: true ,
          width: '15%',
          data:{Title: this.translate.instant('GERAL.WAIT') }
        });

    this.ReservasService.Notifications_MarkRead(ids).subscribe((result: any) => {
      console.log(result);
      W8Dialog.close();

      if (result.Success == true) {
        this.updateNotifications();
        this.popoverRef.clearNotification();
        this.snackbar.open(this.translate.instant('GERAL.NOTIFICATIONREAD'), this.translate.instant('GERAL.CLOSE'), {
          duration: 2000,
          verticalPosition: 'bottom'
        });
      }

    }, error => {

      W8Dialog.close();
      //this.popoverRef.clearNotification();
      this.snackbar.open('ERROR', this.translate.instant('GERAL.CLOSE'), {
        duration: 2000,
        verticalPosition: 'bottom'
      });
      console.log(error);
    });

  }

  updateNotifications() {
    this.ReservasService.Get_Notifications().subscribe((data: any) => {
      if(data) {
        this.notifications = data;

        for(let i=0; i< this.notifications.length; i++)  {
          if(this.notifications[i].ClientStatus == "CRE") {
            this.notifications[i].icon = icAccountCircle;
            this.notifications[i].colorClass = 'text-primary';
          }

          if(this.notifications[i].ClientStatus == "REC") {
            this.notifications[i].icon = icLocalShipping;
            this.notifications[i].colorClass = 'text-primary';
          }

          if(this.notifications[i].ClientStatus == "WHS") {
            this.notifications[i].icon = icHome;
            this.notifications[i].colorClass = 'text-primary';
          }

          if(this.notifications[i].ClientStatus == "DIS") {
            this.notifications[i].icon = icNavigation;
            this.notifications[i].colorClass = 'text-primary';
          }

          if(this.notifications[i].ClientStatus == "DEL") {
            this.notifications[i].icon = icLocationOn;
            this.notifications[i].colorClass = 'text-green';
          }
        }
      }

    }, error => {
      console.log(error);
    });
  }

}
