// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  keytoDecrypt: '@KEYTO#DECR',
  //mapApiKey: 'AIzaSyCMl9f93CP9ITMl6--lxJoBtqCpy02oGjM',
  mapApiKey: 'AIzaSyBZJ8m3N4ju3yqi5GkK5oHmUi-Yrs0Ylz8',
  //PROD
  apiUrl: '/PortalCliente/Portal.xsjs?cmd=',
  apiLoginUrl: '/PortalCliente/Login.xsjs?cmd='
  //apiUrl: 'https://portal.tdv-group.com/PortalCliente/Portal.xsjs?cmd=',
  //apiLoginUrl: 'https://portal.tdv-group.com/PortalCliente/Login.xsjs?cmd='
  //apiUrl: '/proxy/Portal.php?cmd=',
  //apiLoginUrl: '/proxy/Login.php?cmd='
  //Local
  //apiUrl: 'http://148.69.219.175/portalcliente/PortalClienteTestes/Portal.xsjs?cmd=',
  //apiLoginUrl: 'http://148.69.219.175/portalcliente/PortalClienteTestes/Login.xsjs?cmd=',
  //apiUrl: 'http://localhost:4000/PortalCliente/Portal.xsjs?cmd=',
  //apiLoginUrl: 'http://localhost:4000/PortalCliente/Login.xsjs?cmd='
  //DEV
  //apiUrl: '/PortalClienteTestes/Portal.xsjs?cmd=',
  //apiLoginUrl: '/PortalClienteTestes/Login.xsjs?cmd='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
