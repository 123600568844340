import { Injectable } from '@angular/core';
import * as _cryptojs from 'crypto-js'; // Script

@Injectable({
  providedIn: 'root'
})

export class CryptoJS {
  constructor() { }

  // The set method is use for encrypt the value.
  set(keys, value) {
    let key = keys;// _cryptojs.enc.Utf8.parse(keys);
    let iv = keys;// _cryptojs.enc.Utf8.parse(keys);
    let encrypted = _cryptojs.AES.encrypt(_cryptojs.enc.Utf8.parse(value.toString()), key,
    {
        keySize: 128 / 8,
        iv: iv,
        mode: _cryptojs.mode.CBC,
        padding: _cryptojs.pad.Pkcs7
    });

    return encrypted.toString();
  }

  // The get method is use for decrypt the value.
  get(keys, value) {
    let key = _cryptojs.enc.Utf8.parse(keys);
    let iv = _cryptojs.enc.Utf8.parse(keys);
    let decrypted = _cryptojs.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: _cryptojs.mode.CBC,
        padding: _cryptojs.pad.Pkcs7
    });

    return decrypted.toString(_cryptojs.enc.Utf8);
  }
}
