// Angular
import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, ChangeDetectorRef, NgModule } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import icDateRange from '@iconify/icons-ic/date-range';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPerson from '@iconify/icons-ic/twotone-person';
import icMyLocation from '@iconify/icons-ic/twotone-my-location';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icFlag from '@iconify/icons-ic/flag';
import icTimer from '@iconify/icons-ic/timer';
import icTimerOff from '@iconify/icons-ic/timer-off';
import icVisibility from '@iconify/icons-ic/visibility';
import icVisibilityOff from '@iconify/icons-ic/visibility-off';
import icLayers from '@iconify/icons-ic/layers';
import icCode from '@iconify/icons-ic/code';
import icArchive from '@iconify/icons-ic/archive';
import icZoomOut from '@iconify/icons-ic/zoom-out-map';
import icList from '@iconify/icons-ic/list';

import { ReservaService } from '../../../../services/Reservas.service';
import { AuthService } from '../../../../../app/core/auth';
import { TranslateService } from '@ngx-translate/core';
import { NotificationDialog } from './NotificationDialog.component';

import { LoadingFreeze } from './LoadingFreeze';
import {Customer} from "../../../../../../../demo/src/app/pages/apps/aio-table/interfaces/customer.model";


@Component({

    selector: 'NOX-Simulator-Dialog',
    templateUrl: './Simulator.component.html',
	styles: [`
		
    `]
})

export class SimulatorDialog implements OnInit {

    @Output() submitClicked = new EventEmitter<any>();

	form: FormGroup;

	Contact: any = {};
	Countrys: any[];
	PickingTypes: any[];
	Incoterms: any[];
	TransportTypes = [
		{ value: 'LTL', viewValue: 'LTL' },
		{ value: 'FTL', viewValue: 'FTL' },
		{ value: 'PARCEL', viewValue: 'PARCEL' }
		//{ value: 'GRU', viewValue: 'Grupagem' }
	];
	inputType = 'text';
	inputType2 = 'number';
	visible = false;


	icDateRange = icDateRange;
	icMoreVert = icMoreVert;
	icClose = icClose;

	icPrint = icPrint;
	icDownload = icDownload;
	icDelete = icDelete;

	icPerson = icPerson;
	icMyLocation = icMyLocation;
	icLocationCity = icLocationCity;
	icEditLocation = icEditLocation;
	icPhone = icPhone;
	icFlag = icFlag;
	icTimer = icTimer;
	icTimerOff = icTimerOff;
	icVisibility = icVisibility;
	icVisibilityOff = icVisibilityOff;
	icLayers = icLayers;
	icCode = icCode;
	icArchive = icArchive;
	icZoomOut = icZoomOut;
	icList = icList;


    constructor(public dialogRef: MatDialogRef<SimulatorDialog>,
				public ReservasService: ReservaService,
				public auth: AuthService,
				public translate: TranslateService,
				public Dialog: MatDialog,
				private fb: FormBuilder) {
    }

	ngOnInit() {
		this.getCountries();
		this.getPickingTypes();
		this.getIncoterms();
		this.form = this.fb.group({
			from: ['', Validators.required],
			fromCountry: ['', Validators.required],
			to: ['', Validators.required],
			toCountry: ['', Validators.required],
			weight: ['', Validators.required],
			volume: ['', Validators.required],
			transportType: ['LTL'],
			pickingType: ['', Validators.required],
			incoterms: ['', Validators.required]
		});
	}

	onTypeChange() {
    	if(this.form.value.transportType !== 'PARCEL') {
			this.form.controls['incoterms'].setValidators([Validators.required]);
			this.form.controls['incoterms'].updateValueAndValidity();
		} else {
			this.form.controls['incoterms'].setValidators([]);
			this.form.controls['incoterms'].updateValueAndValidity();
		}
	}

    onBackClick(): void {
        this.dialogRef.close();
    }

    onSaveClick(): void 
    {
		let errors;
		//debugger;

		// Validar dados
		const controls = this.form.controls;
		/** check form */
		if (this.form.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		//this.loading = true;

		let toSave = {
			from: controls['from'].value,
			fromCountry: controls['fromCountry'].value,
			to: controls['to'].value,
			toCountry: controls['toCountry'].value,
			weight: controls['weight'].value,
			volume: controls['volume'].value,
			transportType: controls['transportType'].value,
			pickingType: controls['pickingType'].value,
			incoterms: controls['incoterms'].value,
		};


			let W8Dialog = this.Dialog.open(LoadingFreeze,
				{
					panelClass: 'loading-freeze',
					disableClose: true ,
					width: '15%',
					data:{Title: '' }
				});


			this.ReservasService.Simulate(toSave).subscribe((result: any) => {

					W8Dialog.close();

					if (result.Success == true) {

						let RefNotDialog = this.Dialog.open(NotificationDialog,
							{
								width: '450px',
								disableClose: true,
								data: {
									Type: "Success",
									Title: "" ,
									Description: (result.Price == 0) ? this.translate.instant('GERAL.EXPEDITIONCOSTERROR') : (this.translate.instant('GERAL.EXPEDITIONCOST') + result.Price + '€')
									//	Action: result.PrintLabels ? true : false,
									//	Action_Caption: this.translate.instant('RESERVES.PRINT_LABELS'),
								}
							});

						//this.dialogRef.close(toSave);

					} else {

						W8Dialog.close();

						let RefNotDialog = this.Dialog.open(NotificationDialog,
							{
								width: '450px',
								disableClose: true,
								data: {
									Type: "Error",
									Title: "",
									Description: result.Message,
									Action: false,
									Action_Caption: "",
								}
							});

					}

				},
				error => {
					W8Dialog.close();
					console.log(error);
					let RefNotDialog = this.Dialog.open(NotificationDialog,
						{
							width: '450px',
							disableClose: true,
							data: {
								Type: "Error",
								Title: "",
								Description: error.error.error,
								Action: false,
								Action_Caption: ''
							}
						});
				}
			);


    }

    getCountries() {
		let W8Dialog = this.Dialog.open(LoadingFreeze,
			{
				panelClass: 'loading-freeze',
				disableClose: true ,
				width: '15%',
				data:{Title: this.translate.instant('GERAL.WAIT') }
			});

		this.ReservasService.Get_Countrys().subscribe(res => {
			W8Dialog.close();
			// @ts-ignore
			this.Countrys = res;
		},
	error => {
		W8Dialog.close();
		console.log(error);
		let RefNotDialog = this.Dialog.open(NotificationDialog,
			{
				width: '450px',
				disableClose: true,
				data: {
					Type: "Error",
					Title: "",
					Description: error.error.error,
					Action: false,
					Action_Caption: ''
				}
			});
		});
	}

	getPickingTypes() {

		let W8Dialog = this.Dialog.open(LoadingFreeze,
			{
				panelClass: 'loading-freeze',
				disableClose: true ,
				width: '15%',
				data:{Title: this.translate.instant('GERAL.WAIT') }
			});


		this.ReservasService.Get_PickingTypes().subscribe(res => {
				W8Dialog.close();
				// @ts-ignore
				this.PickingTypes = res;
				this.PickingTypes.unshift({label:"", value:""});
			},
			error => {
				W8Dialog.close();
				console.log(error);
				let RefNotDialog = this.Dialog.open(NotificationDialog,
					{
						width: '450px',
						disableClose: true,
						data: {
							Type: "Error",
							Title: "",
							Description: error.error.error,
							Action: false,
							Action_Caption: ''
						}
					});
			});

	}

	getIncoterms() {

		let W8Dialog = this.Dialog.open(LoadingFreeze,
			{
				panelClass: 'loading-freeze',
				disableClose: true ,
				width: '15%',
				data:{Title: this.translate.instant('GERAL.WAIT') }
			});


		this.ReservasService.Get_Incoterms().subscribe(res => {
				W8Dialog.close();
				// @ts-ignore
				this.Incoterms = res;
				this.Incoterms.unshift({AbsEntry:"", Code:""});
			},
			error => {
				W8Dialog.close();
				console.log(error);
				let RefNotDialog = this.Dialog.open(NotificationDialog,
					{
						width: '450px',
						disableClose: true,
						data: {
							Type: "Error",
							Title: "",
							Description: error.error.error,
							Action: false,
							Action_Caption: ''
						}
					});
			});

	}


}
