<div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
     matRipple *ngIf="user$ | async as _user">
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 custom-username" fxHide.xs>{{_user.DisplayName}}</div>
  <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary-light">
    <mat-icon [icIcon]="icPerson"></mat-icon>
  </div>
</div>
