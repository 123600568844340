// Angular
import { Component, OnInit, ViewChild, Inject, Output, EventEmitter, NgModule } from '@angular/core';

import { MatDialog, MatDialogRef,  MAT_DIALOG_DATA } from '@angular/material/dialog';

import {ReservaService}  from '../../../../services/Reservas.service';


@Component({

        selector: 'NOX-LoadingFreeze',
        styleUrls: ['./LoadingFreeze.component.scss'],

        template: `            
            <!--<h1 mat-dialog-title style="color:#646c9a; text-align:center" > {{data.Title}} </h1>-->
            <div mat-dialog-content>
                    <mat-progress-spinner mode="indeterminate" ></mat-progress-spinner>
            </div>
        `
})

export class LoadingFreeze {

        @Output() submitClicked = new EventEmitter<any>();

      
        constructor(public dialogRef: MatDialogRef<LoadingFreeze>,

                @Inject(MAT_DIALOG_DATA) public data: any, public ReservaService: ReservaService) 
                {
                    
                }

}
