import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    constructor(private translate: TranslateService) {
        super();

        this.translate.onLangChange.subscribe((e: Event) => {
            this.getAndInitTranslations();
        });

        this.getAndInitTranslations();
    }

    getAndInitTranslations() {
        this.translate.stream('init').subscribe(translation => {
            if(this.translate.currentLang == 'pt') {
                this.itemsPerPageLabel = 'Items por página';
                this.nextPageLabel = 'Próxima página';
                this.previousPageLabel = 'Página anterior';
            } else {
                this.itemsPerPageLabel = 'Items per page';
                this.nextPageLabel = 'Next page';
                this.previousPageLabel = 'Previous page';
            }
            this.changes.next();

        });
    }

    getRangeLabel = (page: number, pageSize: number, length: number) =>  {
        if (length === 0 || pageSize === 0) {
            return `0 / ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} / ${length}`;
    }
}
