<div class="dropdown">
  <div class="dropdown-header" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      <div class="dropdown-heading">{{'GERAL.NOTIFICATIONS' | translate}}</div>
      <div class="dropdown-subheading">{{'GERAL.YOUHAVE' | translate}} {{ (notifications) ? notifications.length : 0 }} {{'GERAL.NEWNOTIFICATIONS' | translate}}.</div>
    </div>

    <button [matMenuTriggerFor]="settingsMenu" mat-icon-button type="button">
      <mat-icon [icIcon]="icSettings" class="notifications-header-icon"></mat-icon>
    </button>
  </div>

  <div class="dropdown-content">
    <a *ngFor="let notification of notifications; trackBy: trackById"
       [class.read]="notification.read"
       [routerLink]="[]"
       (click)="MarkRead(notification.id)"
       class="notification"
       fxLayout="row"
       fxLayoutAlign="start center"
       matRipple>
      <ic-icon [icon]="notification.icon"
               [ngClass]="notification.colorClass"
               class="notification-icon"
               fxFlex="none"
               size="24px"></ic-icon>
      <!--<ic-icon [icon]="icNotification"
               [ngClass]="notification.colorClass"
               class="notification-icon"
               fxFlex="none"
               size="24px"></ic-icon>-->
      <div fxFlex="auto">
        <div class="notification-label">{{ notification.label }}</div>
        <div class="notification-description">{{ notification.datetime | relativeDateTime }}</div>
      </div>
      <mat-icon [icIcon]="icChevronRight" class="notification-chevron" fxFlex="none"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer" fxLayout="row" fxLayoutAlign="center center">
    <button color="primary" (click)="MarkRead('all')" style="text-transform:uppercase;"  mat-button type="button">
      {{'GERAL.MARKALLREAD' | translate}}</button>
  </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item (click)="MarkRead('all')">
    <mat-icon [icIcon]="icClearAll"></mat-icon>
    <span>{{'GERAL.MARKALLREAD' | translate}}</span>
  </button>

  <button mat-menu-item>
    <mat-icon [icIcon]="icNotificationsOff"></mat-icon>
    <span>{{'GERAL.DISABLENOTIFICATONS' | translate}}</span>
  </button>
</mat-menu>
