<form [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" style="margin: 0 0 10px;" mat-dialog-title>

        <h2 class="title m-0"
            fxFlex="auto">{{ 'GERAL.SIMULATEEXPEDITION' | translate }}</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">

        <div class="flex flex-col md:flex-row">

            <mat-form-field class="flex-auto mt-6">
                <mat-label>{{'GERAL.FROMPOSTALCODE' | translate}}</mat-label>
                <input [type]="inputType" formControlName="from" autocomplete="off" matInput required cdkFocusInitial>
                <mat-hint>{{'GERAL.POSTALCODEEXAMPLE' | translate}}</mat-hint>
                <mat-error *ngIf="form.get('from').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="mt-6 md:ml-6 flex-auto simulator-country">
                <mat-label>{{ 'GERAL.FROMCOUNTRY' | translate }}</mat-label>
                <mat-select  disabled="false" placeholder="" formControlName="fromCountry" autocomplete="off" required>
                    <mat-option *ngFor="let T of Countrys" [value]="T.Code">
                        {{T.Name}}
                    </mat-option>
                </mat-select>
                <mat-icon [icIcon]="icFlag" class="mr-3" matPrefix></mat-icon>
                <mat-error *ngIf="form.get('fromCountry').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>

        </div>

        <div class="flex flex-col md:flex-row">

            <mat-form-field class="flex-auto mt-6">
                <mat-label>{{'GERAL.TOPOSTALCODE' | translate}}</mat-label>
                <input [type]="inputType" formControlName="to" autocomplete="off" matInput required>
                <mat-hint>{{'GERAL.POSTALCODEEXAMPLE' | translate}}</mat-hint>
                <mat-error *ngIf="form.get('to').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="mt-6 md:ml-6 flex-auto simulator-country">
                <mat-label>{{ 'GERAL.TOCOUNTRY' | translate }}</mat-label>
                <mat-select disabled="false" placeholder="" formControlName="toCountry" autocomplete="off" required>
                    <mat-option *ngFor="let T of Countrys" [value]="T.Code">
                        {{T.Name}}
                    </mat-option>
                </mat-select>
                <mat-icon [icIcon]="icFlag" class="mr-3" matPrefix></mat-icon>
                <mat-error *ngIf="form.get('toCountry').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>

        </div>

        <!--<div class="flex flex-col md:flex-row">-->

            <mat-radio-group color="accent" formControlName="transportType"
                             (change)="onTypeChange()" class="flex-auto mt-6">
                <mat-radio-button [class]="transType.value != 'LTL' ? 'md:ml-6' : ''" color="primary" *ngFor="let transType of TransportTypes"
                                  [value]="transType.value">
                    {{transType.viewValue}}
                </mat-radio-button>
            </mat-radio-group>

            <mat-form-field class="mt-6 flex-auto">
                <mat-label>Incoterms
                    <!--<span [hidden]="!FormEditable" class="required-marker">*</span>-->
                    <span class="required-marker" *ngIf="form.value.transportType !== 'PARCEL'">*</span>
                </mat-label>
                <mat-select formControlName="incoterms" >
                    <mat-option *ngFor="let T of Incoterms" [value]="T.Code">
                        {{T.Code}}
                    </mat-option>
                </mat-select>
                <mat-icon [icIcon]="icList" class="mr-3" matPrefix></mat-icon>
                <mat-hint>{{'GERAL.INCOTERMSREQUIRED' | translate}}</mat-hint>
                <mat-error *ngIf="form.get('incoterms').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="mt-6 flex-auto">
                <mat-label>{{'COLUMNS.TYPE'| translate}}</mat-label>
                <mat-select disabled="false" placeholder="" formControlName="pickingType" autocomplete="off" required>
                    <mat-option *ngFor="let T of PickingTypes" [value]="T.value">
                        {{T.label}}
                    </mat-option>
                </mat-select>
                <mat-icon [icIcon]="icLayers" class="mr-3" matPrefix></mat-icon>
                <mat-hint>{{'GERAL.PRODUCTTYPE' | translate}}</mat-hint>
                <mat-error *ngIf="form.get('pickingType').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>

        <!--</div>-->

        <!--<div class="flex flex-col md:flex-row">-->

            <mat-form-field class="flex-auto mt-6">
                <mat-label>{{'GERAL.WEIGHT' | translate}}</mat-label>
                <input [type]="inputType2" formControlName="weight" autocomplete="off" matInput required>
                <mat-hint>{{'GERAL.INKG' | translate}}</mat-hint>
                <mat-icon [icIcon]="icArchive" class="mr-3" matPrefix></mat-icon>
                <mat-error *ngIf="form.get('weight').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="mt-6 ">
                <mat-label>{{'GERAL.VOLUME' | translate}}</mat-label>
                <input [type]="inputType2" formControlName="volume" autocomplete="off" matInput required>
                <mat-hint>{{'GERAL.INM3' | translate}}</mat-hint>
                <mat-icon [icIcon]="icZoomOut" class="mr-3" matPrefix></mat-icon>
                <mat-error *ngIf="form.get('volume').hasError('required')">{{'GERAL.FIELDREQUIRED' | translate}}</mat-error>
            </mat-form-field>
        <!--</div>-->

    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button (click)="onBackClick()" mat-button mat-dialog-close type="button">{{'GERAL.BACK' | translate}}</button>
        <button (click)="onSaveClick()" color="primary" mat-button type="button">
            {{ 'GERAL.SIMULATE' | translate }}</button>
        <!--<button *ngIf="isUpdateMode()" color="primary" mat-button type="submit">UPDATE CUSTOMER</button>-->
    </mat-dialog-actions>
</form>

