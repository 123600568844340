<form [formGroup]="form">
    <div fxLayout="row" fxLayoutAlign="start center" style="margin: 0 0 10px;" mat-dialog-title>

        <h2 class="title m-0"
            fxFlex="auto">{{ 'GERAL.CHANGEPASSWORD' | translate }}</h2>

        <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
            <mat-icon [icIcon]="icClose"></mat-icon>
        </button>
    </div>

    <mat-divider class="-mx-6 text-border"></mat-divider>

    <mat-dialog-content fxLayout="column">

        <mat-form-field class="mt-6">
            <mat-label>Password</mat-label>
            <input [type]="inputType" formControlName="oldPassword"  matInput required cdkFocusInitial>
            <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="{{'Login.ToggleVisibility' | translate}}" type="button">
                <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
                <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
            </button>
            <mat-hint>{{'Login.ClickEye' | translate}}</mat-hint>
            <mat-error *ngIf="form.get('oldPassword').hasError('required')">{{'Login.PasswordNeeded' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="mt-6">
            <mat-label>{{ 'GERAL.NEWPASSWORD' | translate }}</mat-label>
            <input [type]="inputType" formControlName="password" matInput required>
            <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="{{'Login.ToggleVisibility' | translate}}" type="button">
                <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
                <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
            </button>
            <mat-hint>{{'Login.ClickEye' | translate}}</mat-hint>
            <mat-error *ngIf="form.get('password').hasError('required')">{{'Login.NewPasswordNeeded' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="mt-6">
            <mat-label>{{ 'GERAL.NEWPASSWORDCONFIRM' | translate }}</mat-label>
            <input [type]="inputType" formControlName="passwordConfirm" matInput required>
            <button (click)="toggleVisibility()" mat-icon-button matSuffix matTooltip="{{'Login.ToggleVisibility' | translate}}" type="button">
                <mat-icon *ngIf="visible" [icIcon]="icVisibility"></mat-icon>
                <mat-icon *ngIf="!visible" [icIcon]="icVisibilityOff"></mat-icon>
            </button>
            <mat-hint>{{'GERAL.REPEATPASSWORD' | translate}}</mat-hint>
            <mat-error *ngIf="form.get('passwordConfirm').hasError('required')">{{'Login.RepeatPassword' | translate}}</mat-error>
        </mat-form-field>


    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button (click)="onBackClick()" mat-button mat-dialog-close type="button">{{'GERAL.BACK' | translate}}</button>
        <button (click)="onSaveClick()" color="primary" mat-button type="button">
            {{ 'GERAL.CONFIRM' | translate }}</button>
        <!--<button *ngIf="isUpdateMode()" color="primary" mat-button type="submit">UPDATE CUSTOMER</button>-->
    </mat-dialog-actions>
</form>

