// Angular
import {ChangeDetectorRef, Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpClient} from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {NotificationDialog} from "../../../../views/themes/geral/dialogs/NotificationDialog.component";
// import { debug } from 'util';

// Serviços
import { ReservaService } from '../../../../services/Reservas.service';
import {NavigationEnd, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../../environments/environment";


/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {
	private returnUrl: string;
	lastClick = 0;
	delay = 600000; //10 Minutes

	constructor (private http: HttpClient,
				 private router: Router
	) {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.returnUrl = event.url;
			}
		});
	}
	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// tslint:disable-next-line:no-debugger
		// modify request
		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: `Bearer ${localStorage.getItem('accessToken')}`
		// 	}
		// });
		// console.log('----request----');
		//console.log(request);

		/*** Redirect to Login with Message if Session is Expired on Any "Normal" Request ****/
		if (request && request.url && request.url.includes('?cmd=') &&
			!request.url.includes('Is_ValidToken') && !request.url.includes('Localizar_ReservaExt')
			&& !request.url.includes('Login')) {
			if (this.lastClick < (Date.now() - this.delay)) {
				this.lastClick = Date.now();
				let obj: any = {};
				obj.userid = localStorage.getItem('FioriUser');
				obj.fiori_token = localStorage.getItem(environment.authTokenKey);
				this.http.post(request.url.split('?cmd=').shift() + '?cmd=Is_ValidToken', obj).subscribe((data: any) => {
						if (data !== 'Y') {
							console.log('Invalid Token');
							try { // Clear All Dialogs
								let openModals = document.querySelectorAll(".custom-dialog");
								if(openModals) {
									for(let i = 0; i < openModals.length; i++) {
										let modalHeader = openModals[i].getElementsByClassName("mat-dialog-title");
										if(modalHeader && modalHeader.length > 0) {
											let closeButton : any = modalHeader[0].getElementsByTagName("BUTTON");
											if(closeButton && closeButton.length > 0) {
												closeButton[0].click();
											}
										}
									}
								}
							} catch (e) {console.log(e);}
							this.router.navigate(['/login'], {
								queryParams: {
									returnUrl: this.returnUrl,
									tokenExpired: true
								}
							});
							localStorage.removeItem(environment.authTokenKey);
						}
					},
					error => {
						console.log(error);
					});
			}
		} else if(request && request.url && request.url.includes('Login')) {
			this.lastClick = 0;
		}

		// console.log('--- end of request---');

		return next.handle(request).pipe(
			tap(
				event => {
					if (event instanceof HttpResponse) {
						//console.log('all looks good');
						// http response status code
						//console.log(event.status);
					}
				},
				error => {
					// http response status code
					// console.log('----response----');
					// console.error('status code:');
					// tslint:disable-next-line:no-debugger
					console.error(error.status);
					console.error(error.message);
					// console.log('--- end of response---');
				}
			)
		);
	}
}
